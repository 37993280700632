<template>
  <div class="previous-buyer">
    <div class="previous-buyer-title row">
      <div class="select-type">
        <span :class="{active:type === 1}" @click="selectType(1)">Previous Tenants</span>
        <span :class="{active:type === 2}" @click="selectType(2)">Buyers</span>
      </div>
      <el-select v-show="type===1" v-model="years" placeholder="Select Years" multiple @change="selectYear">
        <el-option v-for="item in dataYear" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <!-- <el-date-picker v-model="selectYear" type="years" placeholder="Select Years"></el-date-picker> -->
    </div>
    <!-- perviour table -->
    <Previous-Table v-show="type===1" v-loading="loading" :data="data" :total="total" @pagesChanged="pagesChangedPrevious" @SortChange="SortChange"></Previous-Table>
    <!-- buyer table -->
    <BuyerTable v-show="type===2"></BuyerTable>
  </div>
</template>
<script>
import PreviousTable from "./PreviousTable";
import BuyerTable from "./BuyerTable";
import { apiGetContacts } from "../../../API/api";
export default {
  components: { PreviousTable, BuyerTable },
  data() {
    return {
      dataYear: [],
      limit: 5,
      // previous
      years: [],
      total: 1,
      current: 1,
      data: [],
      ordering: "",
      loading: false,
      type: 1,
    };
  },
  computed: {
    offset() {
      return (this.current - 1) * this.limit;
    },
    query() {
      return {
        offset: this.offset,
        limit: this.limit,
        lease_year: this.years,
        has_signed_lease: true,
        ordering: this.ordering,
        self: true,
        contact_type: "tenant",
      };
    },
  },
  methods: {
    selectType(type) {
      this.type = type;
    },
    selectYear(){
      this.getPrevious();
    },
    pagesChangedPrevious(number) {
      this.current = number;
      this.getPrevious();
    },
    // 租客排序
    SortChange(val) {
      this.current = 1;
      if (val.order === "ascending") {
        this.ordering = val.prop;
      } else if (val.order === "descending") {
        this.ordering = "-" + val.prop;
      } else {
        this.ordering = "";
      }
      this.getPrevious();
    },
    getPrevious() {
      this.loading = true;
      apiGetContacts(this.query)
        .then((res) => {
          this.data = res.results;
          this.total = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    var myDate = new Date();
    var thisYear = myDate.getFullYear(); // 获取当年年份
    var Section = thisYear - 1996;
    for (var i = 0; i <= Section; i++) {
      this.dataYear.push(thisYear--);
    }
    this.getPrevious();
  },
};
</script>
<style lang="scss" scoped>
.previous-buyer {
  margin-top: 10px;
  background-color: #fff;
  .previous-buyer-title {
    padding: 10px 20px;
    justify-content: space-between;
    position: relative;
    .select-type {
      color: #737a8c;
      span {
        font-family: "Roboto-Medium", sans-serif;
        font-size: 16px;
        &:first-of-type {
          margin-right: 50px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        font-family: "Roboto-Bold", sans-serif;
        font-size: 18px;
        color: #678993;
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border: 1px solid #ebeef5;
    }
  }
  @media (max-width:920px) {
    .previous-buyer-title{
      text-align: left;
      display: block;
      .select-type{
        padding-bottom: 10px;
        margin-bottom: 10px;
       border-bottom: 1px solid #ebeef5;
      }
      &::after{
        border: none;
      }
    }
  }
}
</style>