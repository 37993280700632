<template>
  <div class="task_content">
    <el-dialog :visible.sync="show" :title="title">
      <div class="task_content">
        <el-form :model="data" ref="data">
          <el-form-item>
            <el-switch
              v-model="data.send_email"
              active-color="#678993"
              inactive-text="Email Reminder on Due Date"
            ></el-switch>
          </el-form-item>
          <el-form-item prop="title" :rules="rule.requir">
            <p class="task-title">Title</p>
            <el-input v-model="data.title"></el-input>
          </el-form-item>
          <el-form-item prop="type" :rules="rule.requir">
            <p class="task-title">Type</p>
            <el-select v-model="data.type" placeholder="">
              <el-option
                v-for="item in taskType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <slot name="contact"></slot>
          <el-form-item prop="due_date" :rules="rule.requir">
            <p class="task-title">Due Date</p>
            <el-date-picker
              v-model="data.due_date"
              type="date"
              placeholder
              format=" MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="expireTimeOption"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <p class="task-title">Notes</p>
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2 }"
              v-model="data.note"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="task-btn">
          <el-button class="clear-btn" @click="close">Cancel</el-button>
          <el-button @click="createTask" :loading="loading">Save</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["title","data"],
  data() {
    return {
      expireTimeOption: {
        disabledDate(date) {
          var times = Date.now() - 24 * 60 * 60 * 1000;
          return date.getTime() < times;
        },
      },
      show: false,
      loading: false,
      rule: {
        requir: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: mapState({
    taskType: (state) => state.enum.taskType,
    offset() {
      return (this.current - 1) * this.limit;
    },
    query() {
      return {
        has_signed_lease: false,
        limit: this.limit,
        offset: this.offset,
        is_completed: false,
      };
    },
  }),
  methods: {
    ...mapActions(["getTaskType"]),
    createTask() {
      // this.$refs.data.validate((valid) => {
      //   if (valid) {
          this.$emit("createTask",this.data);
      //   } else {
      //     return false;
      //   }
      // });
      
    },
    close() {
      this.show = false;
    },
  },
  mounted() {
    // this.TaskList();
    if (this.taskType.length === 0) {
      this.getTaskType();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/task.scss";
</style>