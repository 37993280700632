<template>
  <div class="task_dashboard">
    <p class="dashboard_tip">Tools & Resources</p>
    <div class="dashboard_container">
      <div class="dashboard_item" v-for="item in this.data.filter(({is_active})=>is_active)" :span="4">
        <a class="link_top" :href="item.link" target="_black">
          <p class="link_img">
            <img :src="item.image_url ? item.image_url : img" alt />
          </p>
          <p class="link_title">{{item.title}}</p>
        </a>
      </div>
    </div>
    <div class="dashboard_icon"></div>
  </div>
</template>
<script>
export default {
  name: "tool",
  props: ["data"],
  data() {
    return {
      img: require("../../../assets/img/placeholder.png"),
    }
  },
};
</script>
<style lang="scss" scoped>
.task_dashboard {
  background: #ffffff;
  border: 1px solid #678993;
  border-radius: 10px;
  padding: 15px 20px;

  /deep/ .el-divider--horizontal {
    margin: 10px 0;
  }

  .dashboard_tip {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #212121;
    margin: 0;
    margin-bottom: 8px;
    text-align: left;
  }

  .dashboard_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .dashboard_item {
    width: 220px;
    padding: 20px;
  }

  .link_top {
    text-decoration: none;
    height: 67px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .link_img {
      margin: 5px 20px 0 0;

      img {
        width: 60px;
        height: 60px;
      }
    }

    .link_title {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.75px;
      color: #678993;
    }
  }
}

@media (max-width: 768px) {
  .task_dashboard {
    padding: 0px;

    .dashboard_tip {
      display: none;
    }

    .link_img {
      margin: 5px 10px 0 0;

      img {
        width: 40px !important;
        height: 40px !important;
      }
    }

    .link_title {
      font-size: 12px !important;
      line-height: 14.06px !important;
    }

    .dashboard_item {
      width: 145px;
      padding: 5px;
    }
  }
}
</style>
