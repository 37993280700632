<template>
    <div class="buyer-table">
       <div class="table"></div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
  .buyer-table{
      height: 100px;
  }
</style>