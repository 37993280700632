<template>
  <div class="task_box">
    <div class="top-detail">
      <h3 icon="chart">Dashboard</h3>
      <p><i>Welcome,</i> It's great to see you again</p>
    </div>
    <!-- task -->
    <DashboardTool :data = "data"></DashboardTool>
    <kanban-board></kanban-board>
    <div class="row task-row">
      <div class="task-width">
        <Dashboard-Tasks ref="dashboardTask" :taskInfor="taskInfor"></Dashboard-Tasks>
      </div>
    </div>
    <Market-Activity></Market-Activity>
    <Previous-Buyer :data="previous" :total="previousTotalPage" @pagesChanged="pagesChanged" @sortChange="sortChange" />
  </div>
</template>
<script>
import DashboardTool from "./Component/DashboardTools";
import DashboardTasks from "./Component/DashboardTasks";
import MarketActivity from "./Component/MarketActivity";
import PreviousBuyer from "./Component/PreviousBuyer";
import KanbanBoard from "../kanbanBoard";
import { apiGetContacts } from "../../API/api";
import { apiGetExternalTools } from "../../API/api";
import { throws } from "assert";
export default {
  components: { DashboardTool, DashboardTasks, MarketActivity, PreviousBuyer , KanbanBoard},
  data() {
    return {
      loadingPrevious: false,
      loadingNoContact: false,
      taskInfor: {},
      dataYear: [],
      selectdate: "60",
      //Previous Tenants
      previous: [],
      limit: 10,
      previousTotalPage: 1, //所有的页面数量
      previousPageNum: 1, //默认第一页
      yaer: 2020,
      selectYear: [],
      ordering: "",
      data: [],
    };
  },
  computed: {
    previousoffset() {
      return (this.previousPageNum - 1) * this.limit;
    },
    previousquery() {
      return {
        offset: this.previousoffset,
        limit: this.limit,
        lease_year: this.selectYear,
        has_signed_lease: true,
        ordering: this.ordering,
        self: true,
        contact_type: "tenant",
      };
    },
  },
  methods: {
    getToolsAndResources() {
      apiGetExternalTools().then((res) => {
        this.data = res.results;
      })
    },
    fliterYearList() {
      // Previous Tenants
      this.yaer = this.selectYear;
      this.getPrevious();
    },
    getPrevious() {
      this.loadingPrevious = true;
      apiGetContacts(this.previousquery)
        .then((res) => {
          this.loadingPrevious = false;
          this.previous = res.results;
          this.previousTotalPage = res.count;
        })
        .catch((err) => {
          this.loadingPrevious = false;
        });
    },
    previousNext() {
      if (this.previousPageNum == this.totalPagecontact) return;
      this.previousPageNum++;
      this.getPrevious();
    },
    PreviousPre() {
      if (this.previousPageNum == 1) return;
      this.previousPageNum--;
      this.getPrevious();
    },

    sortChange(val) {
      // 租客排序
      this.previousPageNum = 1;
      if (val.order === "ascending") {
        this.ordering = val.prop;
      } else if (val.order === "descending") {
        this.ordering = "-" + val.prop;
      } else {
        this.ordering = "";
      }
      this.getPrevious();
    },
    pagesChanged(number) {
      this.previousPageNum = number;
      this.getPrevious();
    },
    openContact(id) {
      // 跳转到contact详情页
      this.$router.push({ name: "edit-contact", params: { id: id } });
    },
  },
  created() {
    var myDate = new Date();
    var thisYear = myDate.getFullYear(); // 获取当年年份
    var Section = thisYear - 1996;
    for (var i = 0; i <= Section; i++) {
      this.dataYear.push(thisYear--);
    }
    this.getPrevious();
    this.getToolsAndResources();
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/common.scss";

.task_box {
  .top-detail {
    color: #36425d;
    text-align: left;

    h3 {
      font-weight: normal;
      font-family: "Roboto-Regular", sans-serif;
      margin: 0;
      font-style: normal;
      font-size: 23px;
      line-height: 27px;
    }

    p {
      font-size: 16px;
      margin: 0 0 20px 0;
      line-height: 19px;

      i {
        font-style: normal;
        color: #678993;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .task-row {
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;

    .task-width {
      height: 405px;
      background-color: #fff;
      width: calc(100% - 406px);
      border-radius: 8px;
      z-index: 20;
    }

    @media (max-width: 1200px) {
      display: block;

      .task-width {
        width: 100%;
      }
    }

    @media (max-width: 992px) {
      margin-right: 0;
    }
  }

  @media (max-width: 992px) {
    margin-right: 0px;
  }
}

.task_box /deep/ input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #678993;
}

.task_box /deep/ input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #678993;
}

.task_box /deep/ input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #678993;
}

.task_box /deep/ input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #678993;
}
</style>
