<template>
  <div class="previous-table">
    <div class="table">
      <el-table :data="data" @sort-change="SortChange">
        <el-table-column sortable="custom" label="PREVIOUS LEASE" min-width="120">
          <template slot-scope="scope">{{scope.row.updated_at | dateFormat}}</template>
        </el-table-column>
        <el-table-column label="ADDRESS" prop="city" sortable="custom" min-width="150">
          <template slot-scope="scope">
            <p v-for="(item,index) in scope.row.leases" :key="index" style="margin:0;">{{item.rental_address }}</p>
          </template>
        </el-table-column>
        <el-table-column label="TENANT" sortable="custom" prop="last_name" min-width="130">
          <template slot-scope="scope">{{scope.row.first_name}} {{scope.row.last_name}}</template>
        </el-table-column>
        <el-table-column prop="email1" sortable="custom" label="EMAIL" min-width="150"></el-table-column>
        <el-table-column prop="cell_phone" sortable="custom" label="PHONE"  min-width="130"></el-table-column>
      </el-table>
      <Paging :total="total" :pageSize="5" @number="pagesChanged"></Paging>
    </div>
  </div>
</template>
<script>
import Paging from "../../common/Paging";
export default {
  components: { Paging },
  props: ["data", "total"],
  methods: {
    pagesChanged(number) {
      this.$emit("pagesChanged", number);
    },
    SortChange(val){
        this.$emit("sortChange",val)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
.table /deep/ .el-table{
  th,td{
    &:first-of-type{
      padding-left: 10px;
    }
  }
}
</style>