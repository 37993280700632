<template>
  <div class="dashboard-tasks-box">
    <div class="task_list">
      <div class="list_titlle">
        <span>Tasks</span>
        <el-button class="tasks_item" @click="ReviseClick(1)">+ NEW TASK</el-button>
      </div>
      <div class="table">
        <el-table :data="tasks" v-loading="loadingTask">
          <el-table-column prop="title" min-width="170" label="TYPE">
            <template slot-scope="scope">
              <span @click=" ReviseClick(scope.row.task_uuid, scope.row, scope.$index) " class="task-type" >
                {{ scope.row.type.name }}
              </span></template >
          </el-table-column>
          <el-table-column min-width="170" label="TASK NAME">
            <template slot-scope="scope" ><p @click=" ReviseClick(scope.row.task_uuid, scope.row, scope.$index) " >
                {{ scope.row.title }}
              </p></template >
          </el-table-column>
          <el-table-column label="ASSOCIATED CONTACT" min-width="150">
            <template slot-scope="scope">
              <p @click=" ReviseClick(scope.row.task_uuid, scope.row, scope.$index) " >
                {{ scope.row.contact_name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="DUE DATE" width="120">
            <template slot-scope="scope" ><p  @click=" ReviseClick(scope.row.task_uuid, scope.row, scope.$index)" >
                {{ scope.row.due_date | dateFormat }}
              </p></template>
          </el-table-column>
          <el-table-column width="60">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" content="Complete" placement="top-start">
                <el-button @click="complateTask(scope.row.task_uuid)" class="btn">
                  <img src="../../../assets/icon/ico-check.svg" alt="" />
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="60">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="light"
                content="Open Contact"
                placement="top-start"
              >
                <el-button @click="openContact(scope.row.contact)" class="btn">
                  <img src="../../../assets/icon/ico-contact.svg" alt="" />
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="total" :pageSize="4" @number="pagesChanged"></Paging>
      </div>
    </div>
    <!-- 修改task -->
    <el-dialog :visible.sync="revisetask" :title="title">
      <div class="task_content">
        <el-form :model="data" ref="data">
          <el-form-item>
            <el-switch
              v-model="data.send_email"
              active-color="#678993"
              inactive-text="Email Reminder on Due Date"
            ></el-switch>
          </el-form-item>
          <el-form-item prop="title" :rules="rule.requir">
            <p class="task-title">Title</p>
            <el-input v-model="data.title"></el-input>
          </el-form-item>
          <el-form-item prop="type" :rules="rule.requir">
            <p class="task-title">Type</p>
            <el-select v-model="data.type" placeholder="">
              <el-option
                v-for="item in taskType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            ref="contactName"
            prop="contact_name"
            :rules="rule.requir"
          >
            <p class="task-title">Associate with Contact</p>
            <el-input
              v-model="data.contact_name"
              @click.native="openContactDialog"
              readonly
            ></el-input>
          </el-form-item>

          <el-form-item prop="due_date" :rules="rule.requir">
            <p class="task-title">Due Date</p>
            <el-date-picker
              v-model="data.due_date"
              type="date"
              placeholder
              format=" MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="expireTimeOption"
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <p class="task-title">Notes</p>
            <el-input
              type="textarea"
              resize="none"
              :autosize="{ minRows: 2 }"
              v-model="data.note"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="task-btn">
          <el-button class="clear-btn" @click="clear()">Cancel</el-button>
          <el-button @click="createTask()" :loading="btnLoading"
            >Save</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- <Task :title="title" ref="taskshow" ></Task> -->
    <Contact-Dialog
      ref="contactDialog"
      @select="selectContact"
      :contacts="contacts"
    ></Contact-Dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiGetTasks, apiUpdateTask, apiCreatTask, } from "../../../API/api";
import ContactDialog from "../../common/ContactDialog";
import Paging from "../../common/Paging";
import Task from '../../common/Task.vue'
export default {
  props: ["taskInfor"],
  components: { ContactDialog, Paging , Task},
  data() {
    return {
      expireTimeOption: {
        disabledDate(date) {
          var times = Date.now() - 24 * 60 * 60 * 1000;
          return date.getTime() < times;
        },
      },
      data: {
        send_email: true,
        note: "",
        due_date: null,
        title: "",
        contact_name: "",
        type: "",
      },
      rule: {
        requir: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur"],
          },
        ],
      },
      //   task
      title: "Create new task",
      total: 1,
      current: 1,
      limit: 4,
      tasks: [],
      // 弹出框
      btnLoading: false,
      revisetask: false,
      contacts: ["tenant", "buyer", "seller"],
      taskId: "",
      index: 0,
      loadingTask: false,
    };
  },
  watch: {
    taskInfor(newVal, oldVal) {
      this.data.contact = newVal.contact_id;
      this.data.contact_name =
        this.taskInfor.first_name + " " + this.taskInfor.last_name;
    },
  },
  computed: mapState({
    taskType: (state) => state.enum.taskType,
    offset() {
      return (this.current - 1) * this.limit;
    },
    query() {
      return {
        has_signed_lease: false,
        limit: this.limit,
        offset: this.offset,
        is_completed: false,
      };
    },
  }),
  methods: {
    ...mapActions(["getTaskType"]),
    // 获取task列表
    pagesChanged(number) {
      this.current = number;
      this.TaskList();
    },
    TaskList() {
      this.loadingTask = true;
      apiGetTasks(this.query)
        .then((res) => {
          this.total = res.count;
          this.tasks = res.results;
        })
        .finally(() => {
          this.loadingTask = false;
        });
    },
    complateTask(id) {
      apiUpdateTask(id, { is_completed: true }).then((res) => {
        this.tasks.forEach((item, index) => {
          if (item.task_uuid === id) {
            this.tasks.splice(index, 1);
          }
        });
      });
    },
    clear() {
      this.revisetask = false;
    },
    selectContact(item) {
      this.$set(this.data, "contact", item.contact_id);
      this.$set(
        this.data,
        "contact_name",
        item.first_name + " " + item.last_name
      );
      this.$refs.contactDialog.show = false;
      this.$refs.contactName.clearValidate();
    },
    openContactDialog() {
      this.$refs.contactDialog.getContacts();
      this.$refs.contactDialog.show = true;
    },
    openContact(id) {
      this.$router.push({ name: "edit-contact", params: { id: id } });
    },
    ReviseClick(id, data, index) {
      if (id != 1) {
        this.data = {
          send_email: data.send_email,
          note: data.note,
          due_date: data.due_date,
          title: data.title,
          contact_name: data.contact_name,
          type: data.type.id,
        };
        this.taskId = data.task_uuid;
        this.index = index;
        this.title = "Edit Task";
      } else {
        this.data = { send_email: true };
        this.title = "Create new task";
      }

      this.revisetask = true;
    },
    createTask() {
      this.$refs.data.validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          delete this.data.contact_name;
          if (this.taskId) {
            apiUpdateTask(this.taskId, this.data)
              .then((res) => {
                this.revisetask = false;
                this.$set(this.tasks, this.index, res);
              })
              .finally(() => {
                this.btnLoading = false;
              });
          } else {
            apiCreatTask(this.data)
              .then((res) => {
                this.revisetask = false;
                this.current = 1;
                this.TaskList();
              })
              .finally(() => {
                this.btnLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.TaskList();
    if (this.taskType.length === 0) {
      this.getTaskType();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
@import "../../../css/task.scss";
@import "../../../css/dialog.scss";
.dashboard-tasks-box {
  .task_list {
    width: 100%;
    .list_titlle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      font-size: 20px;
      font-family: "Roboto-Bold", sans-serif;
      .tasks_item {
        border: none;
        color: #678993;
        background-color: #fff;
        padding-right: 0;
        text-align: right;
        font-family: "Roboto-Bold", sans-serif;
        font-size: 14px;
      }
    }
    .table {
      position: relative;
      .mark_complete {
        color: #00b975;
      }
      .btn {
        border: none;
        background: none;
        padding: 0;
        /deep/ i {
          font-size: 22px;
          color: #678993;
        }
      }
      .task-type {
        display: inline-block;
        background-color: #f4f9fc;
        border: 1px solid #e1e9ef;
        padding: 5px 10px 5px 10px;
        border-radius: 2px;
      }
      /deep/ .el-table .cell {
        padding-left: 20px;
      }
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        width: 100%;
        left: 0px;
        border: 0.017857rem solid #ebeef5;
      }
    }
  }
  // 弹出框的头部设置
  /deep/.el-dialog{
   width: 450px;
  }
  .task_content {
    /deep/ .el-switch__label.is-active {
      color: #303133;
    }
  }
  @media (max-width:992px) {
    /deep/.el-dialog{
    width: 90%;
    }
  }
}
</style>