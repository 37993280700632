<template>
  <div id="kanban-board">
    <div class="heading">
      <h2>Pipeline</h2>
      <el-button class="create-new" @click="showCreateNewDialog = true">
        Create a New Opportunity
      </el-button>
    </div>
    <MainBoard :newOpportunityData.sync="newOpportunity"></MainBoard>
    <CreateNewRentalOpportunity
      v-if="showCreateNewDialog"
      :show-dialog.sync="showCreateNewDialog"
      @success="handleCreateRentalSuccess"
    ></CreateNewRentalOpportunity>
  </div>
</template>

<script>
import {apiGetRentalOpportunityById} from '../../API/api'
export default {
  components: {
    CreateNewRentalOpportunity: () =>
      import("./Component/CreateNewRentalOpportunity.vue"),
    MainBoard: () => import("./Component/MainBoard.vue"),
  },
  data() {
    return {
      showCreateNewDialog: false,
      newOpportunity: {},
    };
  },
  methods: {
    async handleCreateRentalSuccess(newId) {
      const response =  await apiGetRentalOpportunityById(newId)
      console.log('response: ', response);
      // this.newOpportunity = reeponse;
    },
  },
};
</script>

<style lang="scss" scoped>
#kanban-board {
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #678993;
  border-radius: 10px;
  padding: 0px 20px 15px 20px;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @media(max-width: 640px){
      flex-direction: column;
    }

    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #212121;
    }
    .create-new {
      margin-left: 200px;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      padding: 16px 40px;
      width: 268px;
      height: 52px;
      background: #1c4a5e;
      border-radius: 4px;
      @media(max-width: 640px){
        margin: 10px auto;
      }
    }
  }
}

@media (max-width: 768px) {
  #kanban-board {
    padding: 0px;
  }
}
</style>
