<template>
  <div class="market-activity">
    <div class="market-title">7-Day Market Activity</div>
    <div class="market-content rent-date row">
      <div class="market-content-item">
        <p class="item-title">New Listings</p>
        <p class="item-number">{{ data.new_listings_count }}</p>
      </div>
      <!-- <div class="market-content-item">
        <p class="item-title">Return to Market</p>
        <p class="item-number">{{data.new_listings_count}}</p>
      </div> -->
      <div class="market-content-item">
        <p class="item-title">Price Change</p>
        <p class="item-number">{{ data.price_change_count }}</p>
      </div>
      <!-- <div class="market-content-item">
        <p class="item-title">Offer to Purchase</p>
        <p class="item-number">{{data.new_listings_count}}</p>
      </div> -->
      <div class="market-content-item">
        <p class="item-title">Sold</p>
        <p class="item-number">{{ data.sold_count }}</p>
      </div>
      <!-- <div class="market-content-item">
        <p class="item-title">Purchase and Sales</p>
        <p class="item-number">{{data.new_listings_count}}</p>
      </div> -->
    </div>
    <div class="date-sale">
      <p class="date-sale-title">Year to Date Sales (vs Previous Year)</p>
      <div class="market-content row">
        <div class="market-content-item">
          <p class="item-title">Total Sales</p>
          <p class="item-number">
            {{ data.total_sales }}
            <i class="fa fa-arrow-up fa-1x" v-if="data.past_total_sales === 0" > <b>oo</b></i >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.total_sales === 0" > -100%</i>
            <i class="fa fa-arrow-up fa-1x" v-else-if="data.total_sales >= data.past_total_sales" > 
              {{ (((data.total_sales - data.past_total_sales) / data.total_sales) * 100) | keepTwoDecimal }}%</i
            >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.past_total_sales > data.total_sales" > -{{  (((data.past_total_sales - data.total_sales) / data.past_total_sales) *100) | keepTwoDecimal }}%</i>
          </p>
        </div>
        <div class="market-content-item">
          <p class="item-title">House Sales</p>
          <p class="item-number"> {{ data.house_sales }}
            <i class="fa fa-arrow-up fa-1x" v-if="data.past_house_sales === 0" > <b>oo</b></i >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.house_sales === 0" > -100%</i >
            <i class="fa fa-arrow-up fa-1x" v-else-if="data.house_sales >= data.past_house_sales"  > 
              {{ (((data.house_sales - data.past_house_sales) / data.house_sales) * 100) | keepTwoDecimal }}%</i >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.past_house_sales > data.house_sales" > 
              -{{ (((data.past_house_sales - data.house_sales) /  data.past_house_sales) *  100) | keepTwoDecimal }}%</i >
          </p>
        </div>
        <div class="market-content-item">
          <p class="item-title">Land Sales</p>
          <p class="item-number">
            {{ data.land_sales }}
            <i class="fa fa-arrow-up fa-1x" v-if="data.past_land_sales === 0" > <b>oo</b></i  >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.land_sales === 0" > -100%</i >
            <i class="fa fa-arrow-up fa-1x" v-else-if="  data.land_sales >= data.past_land_sales "> 
              {{ (((data.land_sales - data.past_land_sales) / data.land_sales) * 100) | keepTwoDecimal }}%</i>
            <i class="fa fa-arrow-down fa-1x" v-else-if=" data.past_land_sales > data.land_sales " > 
              -{{ (((data.past_land_sales - data.land_sales) / data.past_land_sales) * 100) | keepTwoDecimal }}%</i>
          </p>
        </div>
        <div class="market-content-item">
          <p class="item-title">Average Sales Price</p>
          <p class="item-number">
            ${{ data.avg_total_price | currencyPrice(" ",0)}}
            <i class="fa fa-arrow-up fa-1x" v-if="data.past_avg_total_price === 0" > <b>oo</b></i >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.avg_total_price === 0" > -100%</i >
            <i class="fa fa-arrow-up fa-1x" v-else-if=" data.avg_total_price >= data.past_avg_total_price " > {{
                (((data.avg_total_price - data.past_avg_total_price) / data.past_avg_total_price) *  100) | keepTwoDecimal }}%</i >
            <i class="fa fa-arrow-down fa-1x" v-else-if=" data.past_avg_total_price > data.avg_total_price " > 
              -{{ (((data.past_avg_total_price - data.avg_total_price) / data.past_avg_total_price) * 100) | keepTwoDecimal }}%</i >
          </p>
        </div>
        <div class="market-content-item">
          <p class="item-title">Average House Price</p>
          <p class="item-number">
            ${{ data.avg_house_price | currencyPrice(" ",0) }}
            <i class="fa fa-arrow-up fa-1x" v-if="data.past_avg_house_price === 0" > <b>oo</b></i >
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.avg_house_price === 0" > -100%</i >
            <i class="fa fa-arrow-up fa-1x" v-else-if=" data.avg_house_price != 0 && data.past_avg_house_price != 0 &&  data.avg_house_price >= data.past_avg_house_price " > {{
                (((data.avg_house_price - data.past_avg_house_price) / data.past_avg_house_price) * 100) | keepTwoDecimal }}%</i >
            <i class="fa fa-arrow-down fa-1x" v-else-if=" data.avg_house_price != 0 && data.past_avg_house_price != 0 &&  data.past_avg_house_price > data.avg_house_price " > -{{
                (((data.avg_house_price - data.avg_house_price) / data.past_avg_house_price) * 100) | keepTwoDecimal }}%</i >
          </p>
        </div>
        <div class="market-content-item">
          <p class="item-title">Average Land Price</p>
          <p class="item-number">
            ${{ data.avg_land_price | currencyPrice(" ",0) }}
            <i class="fa fa-arrow-up fa-1x" v-if="data.past_avg_land_price === 0" > <b>oo</b></i>
            <i class="fa fa-arrow-down fa-1x" v-else-if="data.avg_land_price === 0" > -100%</i >
            <i class="fa fa-arrow-up fa-1x" v-else-if=" data.avg_land_price != 0 && data.past_avg_land_price != 0 && data.avg_land_price >= data.past_avg_land_price "
              > {{ (((data.avg_land_price - data.past_avg_land_price) / data.past_avg_land_price) * 100) | keepTwoDecimal }}%</i >
            <i class="fa fa-arrow-down fa-1x"  v-else-if=" data.avg_land_price != 0 && data.past_avg_land_price != 0 && data.past_avg_land_price > data.avg_land_price "
              > -{{ (((data.past_avg_land_price - data.avg_land_price) / data.past_avg_land_price) * 100)  | keepTwoDecimal }}%</i >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiGetListingSummary } from "../../../API/api";
export default {
  data() {
    return {
      data: {},
    };
  },
  filters: {
    keepTwoDecimal(num) {
      // 采用四舍五入，保存两位小数
      return Math.round(parseFloat(num) * 100) / 100;
    },
  },
  created() {
    
    apiGetListingSummary().then((res) => {
      this.data = res;
    });
  },
};
</script>
<style lang="scss" scoped>
.market-activity {
  background-color: #fff;
  /deep/ .row{
    margin:0px !important;
  }
  .market-title {
    position: relative;
    font-family: "Roboto-Bold", sans-serif;
    font-size: 24px;
    padding: 10px 20px;
    text-align: left;
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      width: 100%;
      left: 0px;
      border: 0.007857rem solid #ebeef5;
    }
  }
  .market-content {
    padding: 10px 20px 0;
    color: #38425b;
    justify-content: flex-start;
    position: relative;
    .market-content-item {
      text-align: left;
      width: calc(100% / 6);
      .item-title {
        font-size: 14px;
        margin-bottom: 3px;
        font-family: "Roboto-Regular", sans-serif;
      }
      .item-number {
        margin-top: 0;
        font-size: 22px;
        font-family: "Roboto-Bold", sans-serif;
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      width: calc(100% - 40px);
      left: 20px;
      right: 20px;
      border-bottom: 1px dashed #ebeef5;
    }
  }
  .date-sale {
    .date-sale-title {
      padding: 0 20px;
      font-family: "Roboto-Bold", sans-serif;
      font-size: 20px;
      text-align: left;
      margin-bottom: 0px;
    }
    .market-content {
      padding-top: 0;
      .fa.fa-arrow-up {
        color: #37a000;
        font-size: 14px;
      }
      .fa.fa-arrow-down {
        color: #de1b4b;
        font-size: 14px;
      }
      &::after {
        display: none;
      }
    }
  }
  @media (max-width: 1200px) {
    .market-content{
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: flex-start;
      .market-content-item {
        width: calc(100% / 3);
      }
    }
  }
  @media (max-width: 920px) {
    .market-content .market-content-item {
      width: calc(100% / 2);
    }
  }
}
</style>